import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';
import {Box} from '@chakra-ui/react';

const CustomTooltip = ({active, payload, label}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <Box p={4} shadow="md" borderWidth="1px" bgColor="#FFFFFF">
        <p>
          <b>{label}</b>
        </p>
        <p>
          Процент распознавания: <b>{data.successPercent.toFixed(2)}%</b>
        </p>
        <p>
          Всего документов: <b>{data.cards} </b>
        </p>
        <p>
          Распознано документов: <b>{data.successCards}</b>
        </p>
        <p>
          Документы с ошибками: <b>{data.errorCards}</b>
        </p>
        <p>
          Пустые документы: <b>{data.emptyCards}</b>
        </p>
      </Box>
    );
  }

  return null;
};

const Chart = observer(() => {
  const {cardsLog} = useStore();

  return (
    <LineChart width={976} height={400} data={cardsLog.statistics[cardsLog.chartType]} margin={{top: 30}}>
      <Line type="monotone" dataKey="successPercent" stroke="#82ca9d" />
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="period" />
      <YAxis type="number" domain={[0, 100]} unit="%" />
      <Tooltip cursor={{stroke: '#82ca9d', strokeWidth: 2}} content={<CustomTooltip />} />
    </LineChart>
  );
});

export default Chart;
