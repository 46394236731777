const APP_STATE = {
  INIT: 'init',
  RUN: 'run',
};

const LOCAL_STORAGE = {
  TOKENS: 'tokens',
  ACCESS_TOKEN_EXP: 'accessTokenExp',
};

const ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
};

const ERRORS = {
  OCCURRED: 'Произошла ошибка',
  GET_ANALYTICS_ERROR: 'Ошибка получения аналитики',
  GLOBAL_ERROR: 'Возникла системная ошибка, попробуйте позже',
  INVALID_FIN_ANALYSIS_DATE: 'Документ не подходит под дату фин. анализа',
  COMPANY_NAME_NOT_MATCHING: 'Наименование компании не совпадает папке компании',
  DOCUMENTS_INVALID_FIN_ANALYSIS_DATE: 'Документы не подходят под дату фин. анализа',
  UNKNOWN: 'Неизвестная ошибка',
  SIMILAR_DOCS_IN_LIST: 'В списке схожие документы',
};

const WORDS = {
  UPLOADED: 'Загружено',
  QUEUE_STARTED: 'Документы поставлены в очередь на загрузку',
  DOCUMENTS_UPLOADED: 'Документы загружены',
  DOCUMENTS_UPLOADED_ALL_DOUBLES: 'Все загруженные документы были добавлены в сервис ранее',
  DOCUMENTS_NOT_REQUIRED: 'Документы не требуются',
  DOCUMENTS: 'Документы',
  DOCUMENTS_LIST: 'Список документов',
  UPLOAD: 'Загрузить',
  UPLOAD_ERROR: 'При загрузке произошла ошибка',
  UPLOAD_ERRORS: 'При загрузке произошли ошибки',
  UPLOAD_LABEL: 'Нажмите или перетащите, чтобы добавить файлы',
  NOT_CHOSEN: 'Не выбрано',
  DOCUMENT_NOT_CHOSEN: 'Документ не выбран',
  DOCUMENTS_NOT_CHOSEN: 'Документы не выбраны',
  GROUP_NOT_CHOSEN: 'Группа не выбрана',
  GROUPING: 'Группировать',
  NOT_SPECIFIED: 'Не указано',
  DATA_UPDATE: 'Данные обновлены',
  DATA_RECEIVED: 'Данные получены',
  DATA_ABSENT: 'Данные отсутствуют',
  DATA_NOT_FOUND: 'Данные не найдены',
  DELETED: 'Удалено',
  DELETE: 'Удалить',
  DELETE_MANY: count => `Удалить выбранные документы (${count} шт)`,
  DOCUMENT_DELETED: 'Документ удален',
  DOCUMENTS_DELETED: 'Документы удалены',
  UPDATED: 'Обновлено',
  OPERATION_UPDATE: 'Операция обновлена',
  CONTRACT_UPDATE: 'Контракт обновлен',
  EDIT: 'Редактировать',
  SAVE: 'Сохранить',
  UNDO: 'Отменить',
  SAVING: 'Сохранение',
  DELETING: 'Удаление',
  TOTAL: 'Итого',
  OTHER: 'Прочие',
  ANALYTICS_NOT_SPECIFIED: 'Аналитика отсутствует',
  DATA_NOT_SPECIFIED: 'Данные отсутствуют',
  SECTION_IN_DEVELOPING: 'Раздел в разработке',
  LOADING: 'Загрузка',
  CREATING_REPORT: 'Формирую отчет..',
  GETTING_CONTRACTS: 'Загружаю контракты..',
  GETTING_COMPANIES: 'Загружаю компании..',
  ADD: 'Добавить',
  CANCEL: 'Отмена',
  CLOSE: 'Закрыть',
  TO_WORK: 'В работу',
  CHOOSE: 'Выбрать',
  PROCESSING: 'Обработка',
  DOWNLOAD: 'Скачать',
  RESET: 'Сбросить',
  CREATE_GROUP_COMPANIES: 'Создайте группу компаний',
  ADD_COMPANY: 'Добавить компанию',
  ADDING_COMPANY: 'Добавление компании',
  AFFILATES_COMPANIES: 'Аффилированные компании',
  ENABLE_TRACKING: 'Включить отслеживание',
  CREATE: 'Создать',
  ADD_TO_ANALYSIS: 'Добавить к анализу',
  SETTINGS: 'Настройки',
  UPLOAD_DOCUMENTS: 'Загрузите документы',
  DOCUMENTS_NOT_UPLOADED: 'Документы не загружены',
  STOP_UPLOAD_DOCUMENTS: 'Прервать загрузку',
  LOGOUT: 'Выход',
  CLOSE_TICKET: 'Закрыть тикет',
  ACCEPT: 'Ok',
};

const DESC = {
  BS_CHOSEN_DOCS: 'Необходимо выбрать хотя бы одну карточку 51 счета или общую ОСВ',
  DOCUMENTS_INVALID_FIN_ANALYSIS_DATE: 'Не один из выбранных документов не подходит под дату фин. анализа',
};

const DOCUMENTS_TYPES = {
  OSV_FULL: {value: 'OSV_FULL', label: 'ОСВ общая'},
  OSV_001: {value: 'OSV_001', label: 'ОСВ счета 001'},
  OSV_003: {value: 'OSV_003', label: 'ОСВ счета 003'},
  OSV_004: {value: 'OSV_004', label: 'ОСВ счета 004'},
  OSV_005: {value: 'OSV_005', label: 'ОСВ счета 005'},
  OSV_008: {value: 'OSV_008', label: 'ОСВ счета 008'},
  OSV_009: {value: 'OSV_009', label: 'ОСВ счета 009'},
  OSV_01: {value: 'OSV_01', label: 'ОСВ счета 01'},
  OSV_02: {value: 'OSV_02', label: 'ОСВ счета 02'},
  OSV_08: {value: 'OSV_08', label: 'ОСВ счета 08'},
  OSV_10: {value: 'OSV_10', label: 'ОСВ счета 10'},
  OSV_21: {value: 'OSV_21', label: 'ОСВ счета 21'},
  OSV_23: {value: 'OSV_23', label: 'ОСВ счета 23'},
  OSV_28: {value: 'OSV_28', label: 'ОСВ счета 28'},
  OSV_41: {value: 'OSV_41', label: 'ОСВ счета 41'},
  OSV_43: {value: 'OSV_43', label: 'ОСВ счета 43'},
  OSV_44: {value: 'OSV_44', label: 'ОСВ счета 44'},
  OSV_45: {value: 'OSV_45', label: 'ОСВ счета 45'},
  OSV_46: {value: 'OSV_46', label: 'ОСВ счета 46'},
  OSV_58: {value: 'OSV_58', label: 'ОСВ счета 58'},
  OSV_60: {value: 'OSV_60', label: 'ОСВ счета 60'},
  OSV_62: {value: 'OSV_62', label: 'ОСВ счета 62'},
  OSV_63: {value: 'OSV_63', label: 'ОСВ счета 63'},
  OSV_66: {value: 'OSV_66', label: 'ОСВ счета 66'},
  OSV_67: {value: 'OSV_67', label: 'ОСВ счета 67'},
  OSV_76: {value: 'OSV_76', label: 'ОСВ счета 76'},
  OSV_90: {value: 'OSV_90', label: 'ОСВ счета 90'},
  OSV_91: {value: 'OSV_91', label: 'ОСВ счета 91'},
  SCORE20: {value: 'SCORE20', label: 'Карточка 20 сч'},
  SCORE23: {value: 'SCORE23', label: 'Карточка 23 сч'},
  SCORE25: {value: 'SCORE25', label: 'Карточка 25 сч'},
  SCORE26: {value: 'SCORE26', label: 'Карточка 26 сч'},
  SCORE41: {value: 'SCORE41', label: 'Карточка 41 сч'},
  SCORE44: {value: 'SCORE44', label: 'Карточка 44 сч'},
  SCORE50: {value: 'SCORE50', label: 'Карточка 50 сч'},
  SCORE51: {value: 'SCORE51', label: 'Карточка 51 сч'},
  SCORE52: {value: 'SCORE52', label: 'Карточка 52 сч'},
  SCORE55: {value: 'SCORE55', label: 'Карточка 55 сч'},
  SCORE57: {value: 'SCORE57', label: 'Карточка 57 сч'},
  SCORE68: {value: 'SCORE68', label: 'Карточка 68 сч'},
  SCORE70: {value: 'SCORE70', label: 'Карточка 70 сч'},
  SCORE90: {value: 'SCORE90', label: 'Карточка 90 сч'},
  SCORE91: {value: 'SCORE91', label: 'Карточка 91 сч'},
  BALANCE: {value: 'BALANCE', label: 'Бух.баланс'},
  BALANCE_PAGE_2: {value: 'BALANCE_PAGE_2', label: 'Бух.баланс'},
  OFR: {value: 'OFR', label: 'ОФР'},
  OFR_PAGE_2: {value: 'OFR_PAGE_2', label: 'ОФР'},
  OSV: {value: 'OSV', label: 'ОСВ'},
  SCORE: {value: 'SCORE', label: 'Карточки счетов'},
};

const ROUTES = {
  DASHBOARD: {
    label: 'Кабинет',
    value: '/dashboard',
    CHATS: {label: 'Чаты', value: '/dashboard/chats'},
    LOGS: {label: 'Логи', value: '/dashboard/logs'},
    SUPPORT_STATS: {label: 'Статистика', value: '/dashboard/supportstats'},
    NOTIFICATIONS: {label: 'Уведомления', value: '/dashboard/notifications'},
  },
  START_PAGE: {
    label: '',
    value: '/cabinet/start',
  },
  CABINET: {
    label: 'Кабинет',
    value: '/cabinet',
    ADMIN: {
      label: 'Настройки',
      value: '/cabinet/admin/settings',
      USERS: {
        label: 'Пользователи',
        value: '/cabinet/admin/users',
      },
      CODES: {
        label: 'Суб. счета',
        value: '/cabinet/admin/accountCodes',
      },
      AUTH_LOGS: {
        label: 'Логи',
        value: '/cabinet/admin/logs',
      },
      CASHFLOW: {
        label: 'Настройки Cashflow',
        value: '/cabinet/admin/cashflow',
      },
      CHECKS: {
        label: 'Настройки проверок',
        value: '/cabinet/admin/checks',
      },
    },
    GENERAL_ANALYTICS: {
      label: 'Компании',
      value: '/cabinet/general_analytics',
      BUSSINESS_SCHEMA: {
        label: 'Схема бизнеса',
        value: '/cabinet/general_analytics/bussiness_schema',
      },
      CONTRACTORS_LIGHTS: {
        label: 'Светофор контрагентов',
        value: '/cabinet/general_analytics/contractors_lights',
      },
    },
    ANALYTICS: {
      label: 'Аналитика',
      value: '/cabinet/analytics',
      COMPANY_INFO: {
        label: 'Общие данные',
        value: '/cabinet/analytics/company_info',
        documentsTypes: [], //...Object.values(DOCUMENTS_TYPES).map(type => type.value)],
      },
      SUBACCOUNTS: {
        label: 'По субсчетам',
        value: '/cabinet/analytics/subaccounts',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      MONTHS: {
        label: 'По месяцам',
        value: '/cabinet/analytics/months',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      CONTRACTORS: {
        label: 'По контрагентам',
        value: '/cabinet/analytics/contractors',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      BANKS: {
        label: 'По банкам',
        value: '/cabinet/analytics/banks',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      GROUPS: {
        label: 'По группам',
        value: '/cabinet/analytics/groups',
        documentsTypes: [],
      },
      CASHFLOW: {
        label: 'Сashflow',
        value: '/cabinet/analytics/cashflow',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      CONTRACTS: {
        label: 'Контрактная база',
        value: '/cabinet/analytics/contracts',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      OVERDRAFT: {
        label: 'Расчет лимита овердрафта',
        value: '/cabinet/analytics/overdraft',
        documentsTypes: [DOCUMENTS_TYPES.SCORE51.value],
      },
      BALANCE: {
        label: 'Баланс по форме 1',
        value: '/cabinet/analytics/balance',
        documentsTypes: [DOCUMENTS_TYPES.BALANCE.value],
      },
      BALANCE_OSV: {
        label: 'Баланс по ОСВ',
        value: '/cabinet/analytics/balance_osv',
        documentsTypes: [DOCUMENTS_TYPES.OSV_FULL.value],
      },
      AGGREGATED_BALANCE: {
        label: 'Агрегированный баланс',
        value: '/cabinet/analytics/aggregated_balance',
        documentsTypes: [DOCUMENTS_TYPES.OSV_FULL.value],
      },
      OFR: {
        label: 'ОФР',
        value: '/cabinet/analytics/ofr',
        documentsTypes: [DOCUMENTS_TYPES.OFR.value],
      },
      OPIU: {
        label: 'ОПиУ',
        value: '/cabinet/analytics/opiu',
        documentsTypes: [],
      },
      ECONOMIC_CONNECTS: {
        label: 'Экономические связи',
        value: '/cabinet/analytics/economic_connects',
        documentsTypes: [],
      },
    },
    DOCUMENTS: {
      label: 'Документы',
      value: '/cabinet/documents',
      UPLOAD: {
        label: 'Загрузить документ',
        value: '/cabinet/documents/upload',
      },
      LIST: {
        label: 'Загруженные документы и группы',
        value: '/cabinet/documents/list',
      },
      EDIT: {
        label: 'Редактирование',
        DOCUMENT: {
          label: 'Документ',
          value: '/cabinet/documents/edit_document',
        },
        COMPANIES: {
          label: 'Контрагенты',
          value: '/cabinet/documents/edit_companies',
        },
      },
    },
  },
};
// Переделать на DOCUMENTS_TYPES
const DOCUMENTS = {
  OSV: {
    label: 'ОСВ',
    value: 'OSV',
    startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value,
  },
  SCORE20: {value: 'SCORE20', label: 'Карточка 20 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE23: {value: 'SCORE23', label: 'Карточка 23 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE25: {value: 'SCORE25', label: 'Карточка 25 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE26: {value: 'SCORE26', label: 'Карточка 26 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE41: {value: 'SCORE41', label: 'Карточка 41 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE44: {value: 'SCORE44', label: 'Карточка 44 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE50: {value: 'SCORE50', label: 'Карточка 50 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE51: {value: 'SCORE51', label: 'Карточка 51 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE52: {value: 'SCORE52', label: 'Карточка 52 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE55: {value: 'SCORE55', label: 'Карточка 55 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE57: {value: 'SCORE57', label: 'Карточка 57 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE68: {value: 'SCORE68', label: 'Карточка 68 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE70: {value: 'SCORE70', label: 'Карточка 70 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE90: {value: 'SCORE90', label: 'Карточка 90 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  SCORE91: {value: 'SCORE91', label: 'Карточка 91 сч', startPage: ROUTES.CABINET.DOCUMENTS.EDIT.DOCUMENT.value},
  BALANCE: {
    label: 'Бух. баланс',
    value: 'BALANCE',
    startPage: ROUTES.CABINET.ANALYTICS.BALANCE.value,
  },
  BALANCE_PAGE_2: {
    label: 'Бух. баланс',
    value: 'BALANCE_PAGE_2',
    startPage: ROUTES.CABINET.ANALYTICS.BALANCE.value,
  },
  OFR: {
    label: 'ОФР',
    value: 'OFR',
    startPage: ROUTES.CABINET.ANALYTICS.OFR.value,
  },
  OFR_PAGE_2: {
    label: 'ОФР',
    value: 'OFR_PAGE_2',
    startPage: ROUTES.CABINET.ANALYTICS.OFR.value,
  },
  OPIU: {
    label: 'ОПиУ',
    value: 'OPIU',
    startPage: ROUTES.CABINET.ANALYTICS.OPIU.value,
  },
  OSV_FULL: {value: 'OSV_FULL', label: 'ОСВ общая'},
};

const FILTERS = {
  DATE_RANGE: 'dateRange',
  GROUP_BY: 'groupBy',
  SORT_BY: 'sortBy',
  FILTER_MANY: 'filterMany',
};

const UNITS = {
  ONE: {label: '₽', value: 1},
  THOUSAND: {label: 'тыс. ₽', value: 1000},
  MILLION: {label: 'млн. ₽', value: 1000000},
};

const TABLE_LENGTH_MENU = [
  {
    value: 15,
    label: 15,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 60,
    label: 60,
  },
  {
    value: 100,
    label: 100,
  },
];

const TABLE_LENGTH_MENU_MINI = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 40,
    label: 40,
  },
  {
    value: 80,
    label: 80,
  },
];

const OPIU_LINES = {
  PROCEEDS: 'Выручка',
  NET_COST: 'Себестоимость',
  GROSS_PROFIT: 'Валовая прибыль',
  EXPENSES: 'Расходы',
  PRIMARY_ACTIVITY: 'Результат основной деятельности',
  OTHER_PROFIT: 'Прочие доходы',
  OTHER_EXPENSES: 'Прочие расходы',
  PROFIT_BEFORE_TAX: 'Прибыль до налогообложения',
  PROFIT_TAX: 'Налог на прибыль',
  NET_PROFIT: 'Чистая прибыль',
  OWNERS_EXPENSES: 'Расходы собственников',
  PRIMARY_REPAYMENT_CREDITS: 'Погашение основного долга по кредитам',
};

const REPORTS_TYPES = {
  BALANCE: {value: 'BALANCE', label: 'Баланс'},
  CASH_FLOW: {value: 'CASH_FLOW', label: 'Cash Flow'},
  OPIU: {value: 'OPIU', label: 'ОПиУ'},
  LIGHTS: {value: 'LIGHTS', label: 'Светофор'},
};

const DOCX_TEMPLATES = {
  DOCUMENTS_LIST: 'documentsList',
};

const DATE_PICKER_LOCALE = {
  sunday: 'Вс',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  ok: 'Ок',
  today: 'Сегодня',
  yesterday: 'Вчера',
  last7Days: 'Последние 7 дней',
};

const DATE_PICKER_FORMAT = 'DD.MM.YYYY';

const REPORT_NAMES = {
  cf: 'Кэшфлоу',
  group: 'По группам',
  cl: 'Светофор контрагентов',
  osv_balance: 'Баланс по ОСВ',
};

const COMPANY_DETAILS = {
  GOV_CONTRACTS: {value: 'GOV_CONTRACTS', label: 'Контрактная база'},
  EMPTY: {value: 'EMPTY', label: 'Не выбрано'},
};

export {
  APP_STATE,
  LOCAL_STORAGE,
  ROLES,
  ERRORS,
  WORDS,
  DESC,
  DOCUMENTS,
  ROUTES,
  FILTERS,
  UNITS,
  TABLE_LENGTH_MENU,
  TABLE_LENGTH_MENU_MINI,
  OPIU_LINES,
  DOCUMENTS_TYPES,
  REPORTS_TYPES,
  DOCX_TEMPLATES,
  DATE_PICKER_LOCALE,
  DATE_PICKER_FORMAT,
  REPORT_NAMES,
  COMPANY_DETAILS,
};
