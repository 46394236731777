import React, {useState, useRef, useEffect} from 'react';
import {Textarea} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import styled from 'styled-components';
import moment from 'moment';

const Block = styled.div`
  height: 550px;
`;

const Messages = styled.div`
  height: 430px;
  overflow-y: auto;
  margin-bottom: 25px;
`;

const Title = styled.h5`
  text-align: center;
  margin-bottom: 15px;
`;

const Message = styled.div`
  display: inline-block;
  clear: both;
  user-select: none;
  padding: 10px;
  max-width: 425px;
  background-color: #f7f7f7;
  border: 1px solid #dcdcdc;
  border-radius: ${props => (props.left ? '22px 22px 22px 0px' : '22px 22px 0px 22px')};
  word-break: break-all;
  ${props => (props.left ? 'float:left;' : 'float: right;')}
  margin-bottom: 5px;
`;

const InputBlock = styled.div`
  display: inline-block;
  margin-right: 5px;
  width: 100%;
`;

const Timestamp = styled.div`
  ${props => props.left && 'display: flex;justify-content: flex-end;'}
  margin-bottom: 2px;
  font-size: 11px;
  color: #00b5ad;
`;

const Username = styled.div`
  ${props => props.left && 'display: flex;justify-content: flex-end;'}
  font-weight: bold;
`;

const CloseInfo = styled.div`
  color: red;
  padding: 10px;
  border: 1px solid #dcdcdc;
`;

let messagesCount = 0;
const Chat = observer(({messages, title, send}) => {
  const {support} = useStore();
  const [text, setText] = useState('');
  const scrollRef = useRef(null);

  const handleChange = e => setText(e.target.value);

  const handleSend = () => {
    send(text);
    setTimeout(() => setText(''), 1);
  };

  const handleEnter = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      handleSend();
    }
  };

  useEffect(() => {
    if (messagesCount !== messages.length) {
      messagesCount = messages.length;
      return scrollRef.current.scrollBy({top: messages.length * 430, left: 0, behavior: 'instant'});
    }
  }, [messages]);

  return (
    <Block>
      <Messages ref={scrollRef}>
        <Title> {title} </Title>
        {messages.map(msg => (
          <Message left={!msg.isAnswer} key={msg._id}>
            <Username left={!msg.isAnswer}>{msg.username || 'user'}</Username>
            <Timestamp left={!msg.isAnswer}> {moment(msg.createdAt).format('DD MMM HH:mm:ss')} </Timestamp>
            <div> {msg.text} </div>
          </Message>
        ))}
      </Messages>
      {support.selectedIsClosed ? (
        <CloseInfo>{support.selectedTicketData.closingReason}</CloseInfo>
      ) : (
        <InputBlock>
          <Textarea
            autoFocus
            placeholder="Введите сообщение, для отправки нажмите Enter"
            onChange={handleChange}
            onKeyDown={handleEnter}
            value={text}
          />
        </InputBlock>
      )}
    </Block>
  );
});

export default Chat;
