import React from 'react';
import {Box, useColorModeValue} from '@chakra-ui/react';

const DashboardLayout = ({children}) => {
  const bg = useColorModeValue('white', '#011627');

  return (
    <Box as="section" borderWidth={1} shadow="base" bg={bg} my={3} p={3} borderRadius={4}>
      {children}
    </Box>
  );
};

export default DashboardLayout;
