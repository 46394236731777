import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Switch, Route, Redirect} from 'react-router-dom';
import Authorization from '@pages/Authorization';
import Dashboard from '@pages/Dashboard';
import FullScreenLoader from '@components/loaders/FullScreenLoader';

const AppRouter = observer(() => {
  const {appState, user} = useStore();

  if (appState.showRunLoader) return <FullScreenLoader />;

  return (
    <Switch>
      <Redirect exact from="/" to="/authorization" />
      <Route path="/authorization">{user.isAuth ? <Redirect to="/dashboard" /> : <Authorization />}</Route>
      <Route path="/dashboard">{user.isAuth ? <Dashboard /> : <Redirect to="/" />}</Route>
    </Switch>
  );
});

export default AppRouter;
