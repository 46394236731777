/*import React from 'react';
import {Notification} from 'rsuite';
import {ERRORS} from '@config/constants';

const notificationStyle = {width: '280px'};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  info: (msg, duration) =>
    Notification.info({
      title: <b>Информация</b>,
      description: <div style={notificationStyle}>{msg}</div>,
      duration: duration === undefined ? 3000 : duration,
    }),

  success: (msg, duration) =>
    Notification.success({
      title: <b>Успешно</b>,
      description: <div style={notificationStyle}>{msg}</div>,
      duration: duration || 3000,
    }),

  warning: (msg, duration) =>
    Notification.warning({
      title: <b>Внимание</b>,
      description: <div style={notificationStyle}>{msg}</div>,
      duration: duration != null ? duration : 3000,
    }),

  errorRaw: (msg, duration) => {
    if (!msg) window.$chatwoot.toggle();
    return Notification.error({
      title: <b>Ошибка</b>,
      duration: duration != null ? duration : 3000,
      description: msg || ERRORS.GLOBAL_ERROR,
    });
  },

  error: (msg, duration) => {
    console.log(msg);
    if (!msg) window.$chatwoot.toggle();
    return Notification.error({
      title: <b>Ошибка</b>,
      duration: duration || 3000,
      description: msg ? (
        <div style={notificationStyle}>
          {msg
            .split('\n')
            .slice(0, 10)
            .map((text, i) => (
              <p key={i}>{text}</p>
            ))}
        </div>
      ) : (
        ERRORS.GLOBAL_ERROR
      ),
    });
  },
};*/
import {createStandaloneToast} from '@chakra-ui/react';
import {ERRORS} from '@config/constants';

const toast = createStandaloneToast();
const position = 'top-right';

const notifications = {
  error: (description, duration = 5000) =>
    toast({
      title: ERRORS.OCCURRED,
      description,
      status: 'error',
      position,
      duration,
      isClosable: true,
    }),
};

export default notifications;
