import {action, observable, runInAction} from 'mobx';
import {LOCAL_STORAGE} from '@config/constants';
import notification from '@helpers/notification';

class Auth {
  @observable inProgress = false;

  constructor(rootStore) {
    this.api = rootStore.api.auth;
    this.storage = rootStore.storage;
    this.appState = rootStore.appState;
    this.user = rootStore.user;
  }

  @action
  async login(creds) {
    this.inProgress = true;

    try {
      const data = await this.api.login(creds);
      await this.auth(data);
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => (this.inProgress = false));
    }
  }

  @action
  async auth(data) {
    const {accessToken, refreshToken} = data;
    this.storage.setJson(LOCAL_STORAGE.TOKENS, {accessToken, refreshToken});
    await this.appState.run();
  }

  @action logout() {
    this.storage.clear();
    this.user.clear();
    this.appState.stop();
  }
}

export default Auth;
