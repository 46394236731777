import * as yup from 'yup';
import errors from '@config/formErrors';

const schema = yup.object().shape({
  username: yup
    .string()
    .required(errors.REQUIRED)
    // eslint-disable-next-line no-template-curly-in-string
    .min(2, errors.MIN_LENGTH + '${min}'),
  password: yup
    .string()
    .required(errors.REQUIRED)
    // eslint-disable-next-line no-template-curly-in-string
    .min(2, errors.MIN_LENGTH + '${min}'),
});

export default schema;
