import React from 'react'; //{useState}
import {observer} from 'mobx-react-lite';
import {Box, Flex, HStack} from '@chakra-ui/react';
import Navbar from './Navbar';
import UserMenu from './UserMenu';
import ColorModeSwitcher from '@components/ColorModeSwitcher';
//import {NavLink} from 'react-router-dom';
//import {Header, Navbar, Dropdown, Icon, Nav} from 'rsuite';
//import routes from '@components/Header/routes';
import {useStore} from '@stores';
import logo from './logo.png';

const AppHeader = observer(() => {
  const {user} = useStore();

  /*const handleLogout = () => auth.logout();

  // const getRouteByDocType = () => {
  //   if (document.currentCards.includes(DOCUMENTS.SCORE51.value)) return ROUTES.CABINET.ANALYTICS.value;
  //   else if (document.currentCards.includes(DOCUMENTS.BALANCE.value)) return ROUTES.CABINET.ANALYTICS.BALANCE.value;
  //   else if (document.currentCards.includes(DOCUMENTS.OSV.value)) return ROUTES.CABINET.ANALYTICS.BALANCE_OSV.value;
  //   else if (document.currentCards.includes(DOCUMENTS.OFR.value)) return ROUTES.CABINET.ANALYTICS.OFR.value;
  //   else return ROUTES.CABINET.ANALYTICS.value;
  // };

  const headerStyles = {
    padding: '17px 10px 17px 4px',
    height: '56px',
    background: '#98cbcd',
    color: ' #003334',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    cursor: user.isAuth ? 'pointer' : 'auto',
  };

  return (
    <>
      <Header>
        <Navbar appearance="inverse">
          <Navbar.Header>
            <div style={headerStyles}>
              <img src={logo} style={{position: 'absolute', top: '14px'}} alt="logo" />
              <span style={{fontSize: '16px', display: 'inline-block', marginLeft: '33px'}}>
                <b>SOLWARD SUPPORT</b>
              </span>
            </div>
          </Navbar.Header>
          <Navbar.Body>
            {user.isAuth && (
              <>
                <Nav>
                  <Nav.Item id="freshchat-help" onClick={() => alert('todo chat page')}>
                    First Link
                  </Nav.Item>
                </Nav>
              </>
            )}
          </Navbar.Body>
        </Navbar>
      </Header>
    </>
  );*/
  const headerStyles = {
    padding: '17px 10px 17px 4px',
    height: '56px',
    background: '#98cbcd',
    color: ' #003334',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'default',
  };

  return (
    <Box as="header">
      <Flex as="nav" align="center" justify="space-between" borderBottomWidth={1} bgColor="#003334" pr={4}>
        <HStack spacing={8} alignItems={'center'}>
          <div style={headerStyles}>
            <img src={logo} style={{position: 'absolute', top: '14px'}} alt="logo" />
            <span style={{fontSize: '16px', display: 'inline-block', marginLeft: '33px'}}>
              <b>SOLWARD</b>
            </span>
          </div>
          {user.isAuth && <Navbar />}
        </HStack>
        <Box>
          {user.isAuth && <UserMenu />}
          <ColorModeSwitcher />
        </Box>
      </Flex>
    </Box>
  );
});

export default AppHeader;
