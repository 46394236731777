import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Stat, StatLabel, StatNumber, StatGroup} from '@chakra-ui/react';

const StatisticsInfo = observer(() => {
  const {cardsLog} = useStore();

  return (
    <StatGroup>
      <Stat>
        <StatLabel>Процент распознавания</StatLabel>
        <StatNumber>{cardsLog.statistics.successPercent.toFixed(2)}%</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Всего документов</StatLabel>
        <StatNumber>{cardsLog.statistics.cards}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Распознано документов</StatLabel>
        <StatNumber>{cardsLog.statistics.successCards}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Документы с ошибками</StatLabel>
        <StatNumber>{cardsLog.statistics.errorCards}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Пустые документы</StatLabel>
        <StatNumber>{cardsLog.statistics.emptyCards}</StatNumber>
      </Stat>
    </StatGroup>
  );
});

export default StatisticsInfo;
