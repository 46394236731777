import * as Excel from 'exceljs';
import saveAs from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// eslint-disable-next-line import/no-anonymous-default-export
export default async (sheetName, data, toFile, filename) => {
  if (!data.array) return;
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet();

  if (data.head) {
    for (const [i, headString] of data.head.entries()) {
      ws.getCell(`A${i + 1}`).value = headString;
    }
  }

  ws.getRow(1 + (data.head?.length + 1 || 0)).values = data.columns.map(c => c.header);

  // заполняем строки данными из массива
  ws.columns = data.columns.map(column => ({key: column.key, width: column.width}));
  // ws.addRows(data.array);
  if (data.mergeCells === 'group') {
    for (let i = 0; i < 100; i += 4) {
      ws.mergeCells(1, 2 + i, 1, 5 + i);
      ws.getRow(1 + (data.head?.length || 0)).getCell(2 + i).alignment = {vertical: 'middle', horizontal: 'center'};
    }
  }

  for (const rowData of data.array) {
    const row = ws.addRow(rowData);

    if (rowData.backgroundColor) {
      row.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: rowData.backgroundColor}};
    }
    if (rowData.indent) {
      row.getCell(1).alignment = {indent: 2};
    }
    if (rowData.boldFont) {
      row.font = {bold: true};
    }
  }

  // чекаем столбцы и подгоняем ширину под размер значения в ячейках этого столбца
  ws.columns.forEach(column => {
    let maxLength = 0;
    column['eachCell']({includeEmpty: true}, function (cell) {
      const columnLength = cell.value ? cell.value.toString().length : 10;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength < 10 ? 10 : maxLength;
  });

  const buf = await wb.xlsx.writeBuffer();
  return toFile ? saveAs(new Blob([buf]), data.filename ? data.filename + '.xlsx' : 'solward_report.xlsx') : buf;
};
