import React, {useState, useEffect} from 'react';
import {Button, Divider, FlexboxGrid, Icon, IconButton, Panel} from 'rsuite';
import {observer} from 'mobx-react-lite';
// import SectionHeader from '@components/SectionHeader';
import Ticket from './ticket';
import Chat from './chat';
import styled from 'styled-components';
import {Grid, GridItem, Box} from '@chakra-ui/react';
import {useStore} from '@stores';
import {useToggle} from 'react-use';
import CloseTicketModal from './CloseTicketModal';

const StartMessage = styled.div`
  display: flex;
  justify-content: center;
`;
const GridItemStyled = styled(GridItem)`
  height: 500px;
  overflow-y: auto;
`;

const Support = observer(() => {
  const {support} = useStore();
  const {tickets, messages, selectedTicket, ticketTitle} = support;
  const [showCloseTicketModal, toggleShowCloseTicketModal] = useToggle(false);

  const send = text => support.sendMessage(text);

  useEffect(() => {
    support.getTickets();
    support.startGetTicketsInterval();
    return () => {
      support.stopGetTicketsInterval();
    };
  }, []);

  return (
    <>
      <CloseTicketModal isOpen={showCloseTicketModal} onClose={toggleShowCloseTicketModal} />
      <Panel bodyFill bordered style={{padding: 15}}>
        {!tickets.length ? (
          <StartMessage> Пока что нет обращений. </StartMessage>
        ) : (
          <Grid
            h="550px"
            // templateRows="repeat(2, 1fr)"
            templateColumns="repeat(6, 1fr)"
            gap={2}
          >
            <GridItemStyled colSpan={2}>
              {tickets.map(el => (
                <Ticket
                  id={el._id}
                  key={el._id}
                  version={el.version}
                  title={el.title}
                  isClosed={el.isClosed}
                  select={() => support.selectTicket(el._id, el.title)}
                  closingReason={el.closingReason}
                  isActive={el._id === selectedTicket}
                  createdAt={el.createdAt}
                  toggleShowCloseTicketModal={toggleShowCloseTicketModal}
                  setCloseTicketId={support.setCloseTicketId}
                />
              ))}
            </GridItemStyled>

            <GridItem colSpan={4}>
              {selectedTicket ? (
                <Chat messages={messages} title={ticketTitle} send={send} />
              ) : (
                <p> Для загрузки сообщений выберите тикет </p>
              )}
            </GridItem>
          </Grid>
        )}
      </Panel>
    </>
  );
});

export default Support;
