import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Box} from '@chakra-ui/react';
import Control from './Control';
import CardsLogTable from './CardsLogTable';

const CardsLog = observer(() => {
  const {cardsLog} = useStore();

  return (
    <>
      <Control />
      {cardsLog.isEmpty ? <Box mt={3}>Загруженные документы отсутствуют</Box> : cardsLog.isGet ? <CardsLogTable /> : ''}
    </>
  );
});

export default CardsLog;
