import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import {DownloadIcon} from '@chakra-ui/icons';
import StatisticsInfo from './StatisticsInfo';
import Chart from './Chart';
import ChartMenu from './ChartMenu';

const StatisticsModal = observer(({isOpen, onClose}) => {
  const {cardsLog} = useStore();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Статистика</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StatisticsInfo />
            <ChartMenu />
            <Chart />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cardsLog.statisticsReportInProgress}
              variant="outline"
              mr={3}
              rightIcon={<DownloadIcon />}
              onClick={cardsLog.createStatisticsReport}
            >
              Экспорт
            </Button>
            <Button colorScheme="teal" onClick={onClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

//DownloadIcon

export default StatisticsModal;
