//import getTime from 'date-fns/getTime';
import filter from 'lodash/filter';
import gte from 'lodash/gte';
import lte from 'lodash/lte';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import maxBy from 'lodash/maxBy';
import differenceBy from 'lodash/differenceBy';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import partition from 'lodash/partition';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import intersection from 'lodash/intersection';
import intersectionBy from 'lodash/intersectionBy';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import setWith from 'lodash/setWith';
import uniq from 'lodash/uniq';
import round from 'lodash/round';
//import numeral from 'numeral';
//import moment from 'moment';

const debounce = (inner, ms = 0) => {
  let timer = null;
  let resolves = [];

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const result = inner();
      resolves.forEach(r => r(result));
      resolves = [];
    }, ms);

    return new Promise(resolve => resolves.push(resolve));
  };
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*const filtersList = {
  dateRange: (data, range) => {
    if (!range?.length) return [];
    const startDate = getTime(range[0]) - 10800000;
    const endDate = getTime(range[1]) + 10800000;
    return filter(data, o => gte(o.timestamp, startDate) && lte(o.timestamp, endDate));
  },
  groupBy: (data, identity) => groupBy(data, identity),
  sortBy: (data, identity) => sortBy(data, identity),
  filterMany: (data, values) => {
    if (!values.value?.length) return [];
    return filter(data, o => values.value.some(item => item === o[values.field]));
  },
};*/

const _ = {
  filter,
  groupBy,
  sortBy,
  uniqBy,
  flatMap,
  orderBy,
  pick,
  maxBy,
  differenceBy,
  keys,
  partition,
  keyBy,
  values,
  sumBy,
  sum,
  intersection,
  intersectionBy,
  mapValues,
  set,
  merge,
  unset,
  cloneDeep,
  setWith,
  uniq,
  difference,
  round,
};

const closest = (num, arr) => {
  let next = Math.max.apply(Math, arr);
  for (let val = 0; val < arr.length; val++) {
    if (arr[val] >= num && arr[val] < next) next = arr[val];
  }
  return next;
};

const calculatePercent = (total, partial) => {
  const res = (partial * 100) / total;
  return isFinite(res) && !isNaN(res) ? res : 0;
};

//const toMoney = value => numeral(value || 0).format('0,00.00');

const expToDecimal = expNumber => {
  const str = expNumber.toString();
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 10);
    return +expNumber.toFixed(exponent);
  } else {
    return expNumber;
  }
};

/*const toPercent = value => {
  const _value = expToDecimal(value);
  return numeral(_value < 1e-6 ? 0 : _value).format('0.00');
};

const toShortPercent = value => {
  const _value = expToDecimal(value);
  return numeral(_value < 1e-6 ? 0 : _value).format('0.0');
};*/

const arrayToObj = arr => arr.reduce((acc, cur) => ({...acc, ...cur}), {});

const toObjArr = (property, obj, value) => {
  if (obj[property]) {
    obj[property].push(value);
  } else {
    obj[property] = [value];
  }
};

//const dateToTimestamp = date => moment(date, 'DD.MM.YYYY').valueOf();
//const formatDate = date => moment(date).format('DD.MM.YYYY');
//const formatDateDetail = date => moment(date).format('DD.MM.YYYY HH:mm');

/*const getRange = (startDate, endDate, type) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, type);

  const range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type));
  }
  return range;
};

const timestampToMonthYear = timestamp => {
  const date = moment(timestamp).locale('ru');
  return `${date.format('MMMM')} ${date.year()}`;
};*/

export {
  debounce,
  sleep,
  //filtersList,
  _,
  closest,
  calculatePercent,
  //toMoney,
  expToDecimal,
  //toPercent,
  //toShortPercent,
  arrayToObj,
  toObjArr,
  //dateToTimestamp,
  //getRange,
  //timestampToMonthYear,
  //formatDate,
  //formatDateDetail,
};
