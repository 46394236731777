import {action, observable, runInAction} from 'mobx';
import notification from '@helpers/notification';

class Notifications {
  @observable inProgress = false;
  @observable messages = [];

  constructor(rootStore) {
    this.api = rootStore.api.notifications;
  }

  @action async getAll() {
    this.inProgress = true;
    try {
      const data = await this.api.getNotifications();
      runInAction(() => (this.messages = data));
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => (this.inProgress = false));
    }
  }

  @action add() {
    this.messages.push({_state: 'EDIT', message: '', id: new Date().getTime(), _created: true});
  }

  @action async save(id, message) {
    const item = this.messages.find(_item => _item._id === id);
    if (!item) return;
    item.message = message;
    item._state = null;
    if (item._created) {
      let newItem;
      try {
        newItem = await this.api.createNotification(message);
        item._id = newItem._id;
        item._created = false;
      } catch (e) {
        notification.error(e.message);
      }
    } else {
      try {
        await this.api.updateNotification(id, message);
      } catch (e) {
        notification.error(e.message);
      }
    }
    console.log(id, message, item);
  }

  @action edit(id) {
    const activeItem = this.messages.find(_item => _item._id === id);
    if (!activeItem) return;
    activeItem._state = 'EDIT';
  }

  @action async remove(id) {
    const item = this.messages.find(_item => _item._id === id);
    if (!item) return;
    if (item._created) {
      this.messages = this.messages.filter(_item => _item._id !== id);
      return;
    }

    try {
      await this.api.removeNotification(id);
      await this.getAll();
    } catch (e) {
      notification.error(e.message);
    }
  }
}

export default Notifications;
