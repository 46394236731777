import React from 'react';
import {useColorModeValue, Box, Flex} from '@chakra-ui/react';
import {SingleDatepicker} from 'chakra-dayzed-datepicker';

const _configs = {
  dateFormat: 'dd.MM.yyyy',
  dayNames: 'Вск_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  monthNames: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
  firstDayOfWeek: 1,
};

const DatesRange = ({startDate, setStartDate, endDate, setEndDate, disabled = false}) => {
  const bg = useColorModeValue('white', '#011627');

  const _propsConfig = {
    dateNavBtnProps: {
      colorScheme: 'teal',
      variant: 'outline',
    },
    dayOfMonthBtnProps: {
      defaultBtnProps: {
        _hover: {
          background: 'teal.400',
        },
      },
      isInRangeBtnProps: {
        color: bg,
      },
      selectedBtnProps: {
        background: 'teal.400',
        color: bg,
      },
    },
    inputProps: {
      size: 'sm',
      width: '190px',
    },
  };

  return (
    <Flex align="center">
      <Box mr={2}>Период:</Box>
      <Box mr={2}>
        <SingleDatepicker
          disabled={disabled}
          name="date-input"
          date={startDate}
          onDateChange={setStartDate}
          size="medium"
          propsConfigs={_propsConfig}
          configs={_configs}
        />
      </Box>
      {'-'}
      <Box ml={2}>
        <SingleDatepicker
          disabled={disabled}
          name="date-input"
          date={endDate}
          onDateChange={setEndDate}
          size="medium"
          propsConfigs={_propsConfig}
          configs={_configs}
        />
      </Box>
    </Flex>
  );
};

export default DatesRange;
