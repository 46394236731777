import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box} from '@chakra-ui/react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from '@ajna/pagination';

const CardsLogTable = observer(() => {
  const {cardsLog} = useStore();

  const {currentPage, setCurrentPage, pagesCount, pages} = usePagination({
    pagesCount: cardsLog.data.totalPages,
    initialState: {currentPage: 1},
    limits: {
      outer: 2,
      inner: 2,
    },
  });

  const handleChangePage = page => cardsLog.getLogs(page).then(() => setCurrentPage(page));

  if (!cardsLog.data.totalDocs) return <Box mt={3}>Документы отсутствуют</Box>;

  return (
    <Box>
      <Box overflowY="auto" height="685px" my={15}>
        <TableContainer whiteSpace="wrap">
          <Table variant="striped" colorScheme="teal" size="sm">
            <Thead>
              <Tr>
                <Th>Дата</Th>
                <Th>Пользователь</Th>
                <Th>Название</Th>
                <Th>Статус</Th>
                <Th>Причина</Th>
              </Tr>
            </Thead>
            <Tbody>
              {cardsLog.data.docs.map(doc => (
                <Tr key={doc._id}>
                  <Td>{format(parseISO(doc.updatedAt), 'dd.MM.yyyy')}</Td>
                  <Td>{doc.userName}</Td>
                  <Td maxW={380}>{doc.fileName}</Td>
                  <Td>{doc.success ? 'Успешно' : 'Ошибка'}</Td>
                  <Td>{doc.reason ?? '-'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={handleChangePage}
        isDisabled={cardsLog.inProgress}
      >
        <PaginationContainer>
          <PaginationPrevious mr={5}>Назад</PaginationPrevious>
          <PaginationPageGroup>
            {pages.map(page => (
              <PaginationPage key={`pagination_page_${page}`} page={page} />
            ))}
          </PaginationPageGroup>
          <PaginationNext ml={5}>Далее</PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Box>
  );
});

export default CardsLogTable;
