import {observable, action, computed} from 'mobx';
import {APP_STATE, LOCAL_STORAGE} from '@config/constants';

class AppState {
  @observable status = APP_STATE.INIT;

  constructor(rootStore) {
    this.storage = rootStore.storage;
    this.user = rootStore.user;
    this.score51List = rootStore.score51List;
    this.groups = rootStore.groups;
  }

  @action setStatus(status) {
    this.status = status;
  }

  @action async run() {
    const tokens = this.storage.getJSON(LOCAL_STORAGE.TOKENS);
    if (tokens?.accessToken) {
      this.user.setInfo(tokens.accessToken);
      //await Promise.all([this.groups.getGroups({status: 1})]); //this.score51List.getCards()
      this.setStatus(APP_STATE.RUN);
    }
  }

  @action async getData() {
    await Promise.all([this.score51List.getCards(), this.groups.getGroups({status: 1})]); //this.score51List.getCards()
  }

  @action stop() {
    this.setStatus(APP_STATE.INIT);
  }

  @computed get showRunLoader() {
    return this.user.isAuth && this.status !== APP_STATE.RUN;
  }
}

export default AppState;
