import {ROUTES} from '@config/constants';

const navbarRoutes = [
  ROUTES.DASHBOARD.CHATS,
  ROUTES.DASHBOARD.LOGS,
  ROUTES.DASHBOARD.SUPPORT_STATS,
  ROUTES.DASHBOARD.NOTIFICATIONS,
];

export default navbarRoutes;
