import React from 'react';
import {Spinner, Center, Text} from '@chakra-ui/react';

const FullScreenLoader = props => {
  const {message} = props;

  return (
    <Center h="600">
      <Spinner thickness="4px" size="xl" />
      <Text ml={2} fontSize="xl">
        {message || 'Инициализация..'}
      </Text>
    </Center>
  );
};

export default FullScreenLoader;
