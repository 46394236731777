import React from 'react';
import {Link} from '@chakra-ui/react';
import {NavLink} from 'react-router-dom';

const NavItem = ({children, to}) => (
  <Link
    px={2}
    py={1}
    color="white"
    rounded="md"
    _hover={{
      textDecoration: 'none',
      bg: 'rgb(10, 81, 82)',
    }}
    as={NavLink}
    to={to}
    activeClassName="NavItem-active"
  >
    {children}
  </Link>
);

export default NavItem;
