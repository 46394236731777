import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Flex, RadioGroup, Radio, Stack} from '@chakra-ui/react';

const ChartMenu = observer(() => {
  const {cardsLog} = useStore();

  return (
    <Flex justifyContent="flex-end" mt={6} alignItems="flex-end">
      <RadioGroup onChange={cardsLog.setChartType} value={cardsLog.chartType}>
        <Stack direction="row">
          <Radio value={cardsLog.CHART_TYPE.DAYS}>По дням</Radio>
          <Radio value={cardsLog.CHART_TYPE.MONTHS}>По месяцам</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
});

export default ChartMenu;
