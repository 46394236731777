import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Button, useDisclosure} from '@chakra-ui/react';
import StatisticsModal from './StatisticsModal';

const Statistics = observer(() => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {cardsLog} = useStore();

  const handleOnOpen = () => cardsLog.getStatistics().then(() => onOpen());

  return (
    <>
      <Button
        colorScheme="teal"
        size="sm"
        onClick={handleOnOpen}
        isDisabled={!cardsLog.data.totalDocs}
        isLoading={cardsLog.statisticsInProgress}
      >
        Статистика
      </Button>
      <StatisticsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
});

export default Statistics;
