import React, {useEffect} from 'react';
import './styles/rsuite.css';
import './App.sass';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import AppLayout from '@layouts/AppLayout';
import AppRouter from '@routes/AppRouter';

const App = observer(() => {
  const {appState} = useStore();

  useEffect(() => {
    appState.run().then();
  }, [appState]);

  return (
    <AppLayout>
      <AppRouter />
    </AppLayout>
  );
});

export default App;
