import React from 'react';
import {Formik, Form} from 'formik';
import {observer} from 'mobx-react-lite';
import {InputControl, SubmitButton} from 'formik-chakra-ui';
import validationSchema from './validationSchema';
import {useStore} from '@stores';

const AuthForm = observer(() => {
  const {auth} = useStore();

  const handleLogin = values => auth.login(values);

  return (
    <Formik initialValues={{username: '', password: ''}} validationSchema={validationSchema} onSubmit={handleLogin}>
      <Form>
        <InputControl name="username" label="Логин" />
        <InputControl inputProps={{type: 'password'}} name="password" label="Пароль" mt={4} />
        <SubmitButton width="full" mt={6}>
          Войти
        </SubmitButton>
      </Form>
    </Formik>
  );
});

export default AuthForm;
