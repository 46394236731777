import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Menu, MenuButton, Button, MenuList, MenuItem} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {WORDS} from '@config/constants';

const UserMenu = observer(() => {
  const {user, auth} = useStore();

  const handleLogout = () => auth.logout();

  return (
    <Menu>
      <MenuButton as={Button} color="white" variant="unstyled" rightIcon={<ChevronDownIcon />} mr={2}>
        {user.info.username}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleLogout}>{WORDS.LOGOUT}</MenuItem>
      </MenuList>
    </Menu>
  );
});

export default UserMenu;
