const FORM_ERRORS = {
  WRONG_EMAIL: 'Неправильный Email',
  REQUIRED: 'Требуется',
  MIN_LENGTH: 'Минимум ',
  MAX_LENGTH: 'Максимум ',
  ONLY_STRING: 'Только буквы',
  ONLY_DIGIT: 'Только цифры',
  DATES_RANGE: 'Ошибка диапазона дат',
};

export default FORM_ERRORS;
