import React from 'react';
import {useColorMode, useColorModeValue, IconButton} from '@chakra-ui/react';
import {FaMoon, FaSun} from 'react-icons/fa';

const ColorModeSwitcher = props => {
  const {toggleColorMode} = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      size="sm"
      fontSize="md"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      color="white"
      mr={1}
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      _hover={{
        cursor: 'pointer',
      }}
      {...props}
    />
  );
};

export default ColorModeSwitcher;
