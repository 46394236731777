import React, {useState, useEffect} from 'react';
import {Input, Button, List, FlexboxGrid} from 'rsuite';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';

const Notifications = observer(() => {
  const {notifications} = useStore();

  const [input, setInput] = useState('');

  const handleEditState = item => {
    if (item._state === 'EDIT') {
      notifications.save(item._id, input);
      setInput('');
    } else {
      notifications.edit(item._id);
      setInput(item.message);
    }
  };

  const handleRemove = item => {
    console.log(item);
    notifications.remove(item._id);
  };

  const handleCreate = item => {
    notifications.add();
  };

  useEffect(() => {
    notifications.getAll();
  }, []);

  return (
    <>
      <div style={{display: 'flex', marginBottom: '10px'}}>
        <Button onClick={handleCreate}>Создать</Button>
      </div>
      {notifications.messages.length === 0 && <p>Уведомлений нет</p>}
      <List bordered>
        {notifications.messages.map((item, index) => (
          <List.Item key={index} index={index}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={18}>
                {item._state === 'EDIT' ? (
                  <Input
                    defaultValue={item.message}
                    placeholder="Сообщение"
                    onChange={value => {
                      setInput(value);
                    }}
                  />
                ) : (
                  <span>{item.message}</span>
                )}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={3}>
                <Button appearance="link" onClick={() => handleEditState(item)}>
                  {item._state === 'EDIT' ? 'Сохранить' : 'Редактировать'}
                </Button>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={2}>
                <Button appearance="link" onClick={() => handleRemove(item)}>
                  Удалить
                </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
    </>
  );
});

export default Notifications;
