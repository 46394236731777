import {action, observable, computed} from 'mobx';
import notification from '@helpers/notification';
import parseISO from 'date-fns/parseISO';
import {saveAs} from 'file-saver';

class CardsLog {
  @observable inProgress = false;
  @observable statisticsInProgress = false;
  @observable statisticsReportInProgress = false;
  @observable isGet = false;
  @observable clientId = undefined;
  @observable data = {};
  @observable statistics = {};
  @observable range = {};
  @observable startDate = null;
  @observable endDate = null;
  @observable chartType = this.CHART_TYPE.DAYS;

  constructor(rootStore) {
    this.api = rootStore.api.cardsLog;
    this.CHART_TYPE = {
      DAYS: 'days',
      MONTHS: 'months',
    };
  }

  @action setInProgress(value) {
    this.inProgress = value;
  }

  @action setStatisticsInProgress(value) {
    this.statisticsInProgress = value;
  }

  @action setStatisticsReportInProgress(value) {
    this.statisticsReportInProgress = value;
  }

  @action setIsGet(value) {
    this.isGet = value;
  }

  @action setData(data) {
    this.data = data;
  }

  @action setStatistics(statistics) {
    this.statistics = statistics;
    //this.startDate = parseISO(stat.startDate);
    //this.endDate = parseISO(stat.endDate);
  }

  @action.bound async setStartDate(startDate) {
    this.startDate = startDate;
    await this.getLogs();
  }

  @action.bound async setEndDate(endDate) {
    this.endDate = endDate;
    await this.getLogs();
  }

  @action.bound async setClientId(clientId) {
    this.clientId = clientId;
    this.clear();
    if (clientId) await this.getLogs();
  }

  @action setFilters(filters) {
    this.startDate = parseISO(filters.startDate);
    this.endDate = parseISO(filters.endDate);
  }

  @action.bound setChartType(chartType) {
    this.chartType = chartType;
  }

  @action clear() {
    this.startDate = null;
    this.endDate = null;
  }

  @action async getLogs(page) {
    try {
      this.setInProgress(true);
      //this.setIsGet(false);

      const {data, filters} = await this.api.getLogs({
        client: this.clientId,
        page,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.setData(data);
      if (Object.keys(filters).length) this.setFilters(filters);

      this.setIsGet(true);
    } catch (err) {
      notification.error(err.message);
    } finally {
      this.setInProgress(false);
    }
  }

  @action async getStatistics() {
    try {
      this.setStatisticsInProgress(true);
      const statistics = await this.api.getStatistics({
        client: this.clientId,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.setStatistics(statistics);
    } catch (err) {
      notification.error(err.message);
    } finally {
      this.setStatisticsInProgress(false);
    }
  }

  @action.bound async createStatisticsReport() {
    try {
      this.setStatisticsReportInProgress(true);
      const report = await this.api.createStatisticsReport(this.statistics[this.chartType]);
      saveAs(new Blob([report]), `statistics_report.xlsx`);
    } catch (err) {
      notification.error(err.message);
    } finally {
      this.setStatisticsReportInProgress(false);
    }
  }

  @computed get isEmpty() {
    return this.isGet && !this.data.totalDocs && !this.startDate;
  }
}

export default CardsLog;
