import {action, observable} from 'mobx';
import jwtDecode from 'jwt-decode';
import {LOCAL_STORAGE} from '@config/constants';

class User {
  @observable info = {};
  @observable isAuth = false;

  constructor(rootStore) {
    this.storage = rootStore.storage;
  }

  @action setInfo(token) {
    try {
      const userInfo = jwtDecode(token);
      this.info = {...userInfo};
      this.storage.setJson(LOCAL_STORAGE.ACCESS_TOKEN_EXP, userInfo.exp);
      this.isAuth = true;
    } catch (e) {
      this.storage.clear();
    }
  }

  @action clear() {
    this.info = {};
    this.isAuth = false;
  }
}

export default User;
