import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Checkbox,
  Textarea,
} from '@chakra-ui/react';
import {WORDS} from '@config/constants';

const CloseTicketModal = observer(({isOpen, onClose}) => {
  const {support} = useStore();

  const isDefaultReason = support.closeReason === support.defaultReason;

  const handleCloseTicket = () => {
    onClose();
    support.updateTicket().then();
  };

  const handleSetCloseReason = e => support.setCloseReason(e.target.value);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{WORDS.CLOSE_TICKET}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box border="1px" p={4} borderRadius={5} borderColor="#dcdcdc">
            <Heading size="sm">Причина:</Heading>
            <Checkbox my={4} value={support.defaultReason} isChecked={isDefaultReason} onChange={handleSetCloseReason}>
              {support.defaultReason}
            </Checkbox>
            <FormControl>
              <FormLabel htmlFor="customReason">Другая причина</FormLabel>
              <Textarea
                id="customReason"
                isDisabled={isDefaultReason}
                value={isDefaultReason ? '' : support.closeReason}
                onChange={e => support.setCloseReason(e.target.value)}
                rows={5}
              />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{WORDS.CANCEL}</Button>
          <Button colorScheme="blue" ml={3} onClick={handleCloseTicket}>
            {WORDS.ACCEPT}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default CloseTicketModal;
