import React from 'react';
//import {Footer} from 'rsuite';
import {Box} from '@chakra-ui/react';

const footerStyle = {
  height: '50px',
  marginTop: 'auto',
  background: '#003334',
  textAlign: 'center',
  color: '#FFF',
  paddingTop: '10px',
};

const AppFooter = () => (
  <Box as="footer" style={footerStyle}>
    Solward © 2020–2021
  </Box>
);

export default AppFooter;
