import React from 'react';
import NavItem from './NavItem';
import {HStack} from '@chakra-ui/react';
import navbarRoutes from './navbarRoutes';

const Navbar = () => (
  <HStack as="nav" spacing={4} display={{base: 'none', md: 'flex'}}>
    {navbarRoutes.map(route => (
      <NavItem to={route.value} key={route.value}>
        {route.label}
      </NavItem>
    ))}
  </HStack>
);

export default Navbar;
