import axios from 'axios';
import jwtDecode from 'jwt-decode';
import _localStorage from '@helpers/_localStorage';
import {debounce} from '@helpers/func';
import notification from '@helpers/notification';
import {LOCAL_STORAGE} from '@config/constants';

const baseURL = process.env.REACT_APP_SERVER_API;
const REFRESH_TOKEN_URL = 'auth/token';
const TOKEN_EXPIRED = 'Token expired';

const api = axios.create({
  baseURL,
  timeout: 10 * 60 * 1000,
});

const tokensRefresh = async () => {
  try {
    const {refreshToken} = _localStorage.getJSON(LOCAL_STORAGE.TOKENS) || {};
    const res = await axios.post(`${baseURL}/${REFRESH_TOKEN_URL}`, {refreshToken});
    const info = jwtDecode(res.data.accessToken);
    _localStorage.setJson(LOCAL_STORAGE.TOKENS, {
      accessToken: res.data.accessToken,
      refreshToken: res.data.refreshToken,
    });
    _localStorage.setJson(LOCAL_STORAGE.ACCESS_TOKEN_EXP, info.exp);
    return res.data.accessToken;
  } catch (e) {
    notification.error(e.response.data.message || e.message);
    _localStorage.clear();
    window.location.reload();
  }
};

const tokensRefreshWithDebounce = debounce(() => tokensRefresh(), 100);

api.interceptors.response.use(
  res => res.data,
  async error => {
    if (!error.response?.status) {
      return Promise.reject({message: 'Нет соединения с сервером'});
    }
    if (error.response.status !== 401 || error.response.data.message !== TOKEN_EXPIRED) {
      return Promise.reject(error.response.data);
    }
    await tokensRefreshWithDebounce();
    return api.request(error.config);
  }
);

api.interceptors.request.use(
  async config => {
    const {accessToken} = _localStorage.getJSON(LOCAL_STORAGE.TOKENS) || {};
    const accessTokenExp = _localStorage.getJSON(LOCAL_STORAGE.ACCESS_TOKEN_EXP);

    if (accessToken && accessTokenExp) {
      const nowTime = Math.floor(new Date().getTime() / 1000);

      if (accessTokenExp - 10 < nowTime && config.url !== REFRESH_TOKEN_URL) {
        const newAccessToken = await tokensRefreshWithDebounce();
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } else {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    } else if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  e => Promise.reject(e)
);

export default api;
