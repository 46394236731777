import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import styled from 'styled-components';
import moment from 'moment';
import {Tooltip} from '@chakra-ui/react';
import {SpinnerIcon, QuestionOutlineIcon, CloseIcon} from '@chakra-ui/icons';
import {WORDS} from '@config/constants';

const Block = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#e9e9e9' : '#f6f6f6')};
  border-bottom: 1px solid #d3d3d3;
  ${props => props.isActive && 'border: 1px solid #CFCFCF;'}
  border-radius: 5px;
  padding: 10px;

  &:hover {
    background-color: #e9e9e9;
  }
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Timestamp = styled.div`
  font-size: 12px;
  color: #a5a5a5;
`;
const Menu = styled.div`
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`;

const TicketStatus = styled.span`
  color: ${props => (props.isClosed ? 'red' : 'green')};
`;

const TICKET_STATUSES = {OPEN: 'Открыт', CLOSED: 'Закрыт'};

const Ticket = observer(
  ({
    id,
    version,
    title,
    isClosed,
    createdAt,
    isActive,
    select,
    closingReason,
    toggleShowCloseTicketModal,
    setCloseTicketId,
  }) => {
    const {support} = useStore();

    const isUpdate = support.inUpdateTicket.get(id);

    const handleShowCloseTicketModal = e => {
      e.stopPropagation();
      setCloseTicketId(id);
      toggleShowCloseTicketModal();
    };

    return (
      <Block isActive={isActive} onClick={() => select()}>
        <Header>
          <span>
            <Menu>
              <TicketStatus isClosed={isClosed}>
                {isClosed ? TICKET_STATUSES.CLOSED : TICKET_STATUSES.OPEN}
              </TicketStatus>
              <Tooltip label={isClosed ? closingReason : WORDS.CLOSE_TICKET}>
                {isUpdate ? (
                  <SpinnerIcon />
                ) : isClosed ? (
                  <QuestionOutlineIcon />
                ) : (
                  <CloseIcon onClick={handleShowCloseTicketModal} />
                )}
              </Tooltip>
            </Menu>
            [{version}] {title}
          </span>
        </Header>
        <Timestamp> {moment(createdAt).format('создан DD MMMM YYYY HH:mm')} </Timestamp>
      </Block>
    );
  }
);

export default Ticket;
