import React, {useState} from 'react';
import {SelectPicker, Panel, Button} from 'rsuite';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';

const SupportStats = observer(() => {
  const {support} = useStore();

  const [isClosed, setIsClosed] = useState(null);
  const [priority, setPriority] = useState(null);
  const [version, setVersion] = useState(null);

  const downloadStats = () => support.downLoadStats({isClosed, priority, version});

  return (
    <>
      <div style={{width: '120px', marginRight: '15px'}}>
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{marginTop: 8, marginRight: 8, minWidth: 80}}>Статус:</div>
          <SelectPicker
            data={[
              {label: 'Закрыт', value: 'closed'},
              {label: 'Открыт', value: 'open'},
            ]}
            value={isClosed}
            onSelect={value => setIsClosed(value)}
            onClean={() => setIsClosed(null)}
            placeholder="Любой"
            searchable={false}
            cleanable={true}
            style={{minWidth: 160}}
          />
        </div>
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{marginTop: 8, marginRight: 8, minWidth: 80}}>Приоритет:</div>
          <SelectPicker
            data={[1, 2, 3].map(value => ({label: value, value}))}
            value={priority}
            onSelect={value => setPriority(value)}
            onClean={() => setPriority(null)}
            placeholder="Любой"
            searchable={false}
            cleanable={true}
            style={{minWidth: 160}}
          />
        </div>
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{marginTop: 8, marginRight: 8, minWidth: 80}}>Версия:</div>
          <SelectPicker
            data={support.ticketVersions.map(value => ({label: value, value}))}
            value={version}
            onSelect={value => setVersion(value)}
            onClean={() => setVersion(null)}
            placeholder="Любой"
            searchable={false}
            cleanable={true}
            style={{minWidth: 160}}
          />
        </div>
      </div>
      <Button appearance="primary" onClick={() => downloadStats()} loading={support.downloadInProgress}>
        Скачать
      </Button>
    </>
  );
});

export default SupportStats;
