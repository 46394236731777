import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Tabs, TabList, TabPanels, Tab, TabPanel, Spinner} from '@chakra-ui/react';
import CardsLog from './CardsLog';

const Logs = observer(() => {
  const {cardsLog} = useStore();

  return (
    <Tabs>
      <TabList>
        <Tab>Загруженные документы {cardsLog.inProgress && <Spinner ml={2} size="sm" />}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CardsLog />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
});

export default Logs;
