import React from 'react';
import AppHeader from '@components/Header';
import AppFooter from '@components/Footer';
import {Flex, Container, Box} from '@chakra-ui/react';

const AppLayout = props => {
  const {children} = props;

  return (
    <Flex direction="column" height="full">
      <AppHeader />
      <Container maxW="container.xl">
        <Box as="main">{children}</Box>
      </Container>
      <AppFooter />
    </Flex>
  );
};

export default AppLayout;
