import React, {useEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import {Select} from '@chakra-ui/react';

const ClientSelect = observer(({value = undefined, handleSetValue = () => {}, disabled = false}) => {
  const {clients} = useStore();

  useEffect(() => {
    if (!clients.isGet) clients.getList();
  }, [clients]);

  const handleOnChange = useCallback(e => handleSetValue(e.target.value), [handleSetValue]);

  return (
    <Select
      isDisabled={clients.inProgress || disabled}
      placeholder={clients.inProgress ? 'Загрузка' : 'Выберите клиента'}
      size="sm"
      value={value}
      onChange={handleOnChange}
    >
      {clients.list.map(client => (
        <option key={client._id} value={client._id}>
          {client.name}
        </option>
      ))}
    </Select>
  );
});

export default ClientSelect;
