import {createContext, useContext} from 'react';
import {configure} from 'mobx';
import * as auth from '@api/auth';
import * as support from '@api/support';
import * as notifications from '@api/notifications';
import * as clients from '@api/clients';
import * as cardsLog from '@api/cardsLog';
import _localStorage from '@helpers/_localStorage';
import AppState from '@stores/AppState';
import Auth from '@stores/Auth';
import User from '@stores/User';
import Support from '@stores/Support';
import Notifications from '@stores/Notifications';
import Clients from '@stores/Clients';
import CardsLog from '@stores/CardsLog';

configure({enforceActions: 'observed'});

class RootStore {
  constructor() {
    this.api = {
      auth,
      support,
      notifications,
      clients,
      cardsLog,
    };
    this.storage = _localStorage;
    this.user = new User(this);
    this.appState = new AppState(this);
    this.auth = new Auth(this);
    this.support = new Support(this);
    this.notifications = new Notifications(this);
    this.clients = new Clients(this);
    this.cardsLog = new CardsLog(this);
  }
}

const store = new RootStore();
const StoreContext = createContext(store);
const useStore = () => useContext(StoreContext);

export {store, StoreContext, useStore};
