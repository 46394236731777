import React from 'react';
import {useTitle} from 'react-use';
import {Box, Heading, Flex, useColorModeValue} from '@chakra-ui/react';
import AuthForm from './AuthForm';

const Authorization = () => {
  useTitle('Авторизация');

  const bg = useColorModeValue('white', '#011627');

  return (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box
        borderWidth={1}
        px={4}
        width="full"
        maxWidth="500px"
        borderRadius={4}
        textAlign="center"
        boxShadow="md"
        bg={bg}
      >
        <Box p={4}>
          <Box textAlign="center">
            <Heading as="h4" size="md">
              Авторизация
            </Heading>
          </Box>
          <Box my={4} textAlign="left">
            <AuthForm />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default Authorization;
