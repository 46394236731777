import {action, observable, computed, runInAction} from 'mobx';
import notification from '@helpers/notification';
import {_} from '@helpers/func';
//import {setIntervalAsync} from 'set-interval-async/dynamic';
//import {clearIntervalAsync} from 'set-interval-async';

import getSupportStats from '@helpers/toExcel/getData/supportStats';
import toExcel from '@helpers/toExcel/index';

class Support {
  @observable inProgress = false;
  @observable inUpdateTicket = new Map();
  @observable tickets = [];
  @observable messages = [];
  @observable selectedTicket = null;
  @observable closeTicketId = null;
  @observable ticketTitle = '';
  @observable closeReasonList = new Map();

  @observable downloadInProgress = false;

  constructor(rootStore) {
    this.api = rootStore.api.support;
    this.defaultReason = 'Проблема решена';
  }

  @action async selectTicket(id, title) {
    if (this.selectedTicket === id) return;
    this.inProgress = true;
    await this.getMessages(id, true);
    runInAction(() => {
      this.selectedTicket = id;
      this.ticketTitle = title;
      this.inProgress = false;
    });
  }

  @action setCloseReasons() {
    const openTickets = this.tickets.filter(ticket => !ticket.isClosed);
    openTickets.forEach(
      ticket => !this.closeReasonList.has(ticket._id) && this.closeReasonList.set(ticket._id, this.defaultReason)
    );
  }

  @action.bound setCloseTicketId(id) {
    this.closeTicketId = id;
  }

  @computed get closeReason() {
    return this.closeReasonList.get(this.closeTicketId);
  }

  @action.bound setCloseReason(reason) {
    if (this.closeReason === this.defaultReason) {
      this.closeReasonList.set(this.closeTicketId, '');
    } else {
      this.closeReasonList.set(this.closeTicketId, reason);
    }
  }

  @action async getTickets() {
    try {
      const data = await this.api.getTickets();
      runInAction(() => (this.tickets = data));
      this.setCloseReasons();
    } catch (e) {
      notification.error(e.message);
    }
  }

  @action startGetTicketsInterval() {
    this.intervalId = setInterval(() => {
      this.getTickets();
      if (this.selectedTicket) this.getMessages(this.selectedTicket);
    }, 1000);
  }

  @action stopGetTicketsInterval() {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  @action async createTicket(title, text) {
    this.inProgress = true;
    try {
      const ticket = await this.api.createTicket({title, text});
      await this.getTickets();
      this.selectTicket(ticket._id, title);
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => (this.inProgress = false));
    }
  }

  @computed get ticketsIdByIndex() {
    return this.tickets.reduce((acc, cur, ind) => ({...acc, [cur._id]: ind}), {});
  }

  @action.bound async updateTicket() {
    this.inUpdateTicket.set(this.closeTicketId, true);

    try {
      const closingReason = `Закрыто тех.поддержкой. ${this.closeReason}`;
      const result = await this.api.updateTicket(this.closeTicketId, {isClosed: true, closingReason});
      runInAction(() => {
        this.tickets[this.ticketsIdByIndex[this.closeTicketId]] = {
          ...this.tickets[this.ticketsIdByIndex[this.closeTicketId]],
          ...result,
        };
      });
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => this.inUpdateTicket.set(this.closeTicketId, false));
    }
  }

  @action async getMessages(id, needRefresh) {
    this.inProgress = true;
    if (needRefresh) runInAction(() => (this.messages = []));
    try {
      const messages = await this.api.getTicketMessages(id);
      runInAction(() => (this.messages = messages));
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => (this.inProgress = false));
    }
  }

  @action async sendMessage(text) {
    this.inProgress = true;
    try {
      const messages = await this.api.sendMessage(this.selectedTicket, text);
      runInAction(() => (this.messages = messages));
    } catch (e) {
      notification.error(e.message);
    } finally {
      runInAction(() => (this.inProgress = false));
    }
  }

  @action async downLoadStats(filters) {
    this.downloadInProgress = true;
    try {
      const data = await getSupportStats(this.tickets, filters);
      await toExcel('', data, true);
    } catch (e) {
      console.log(e)
      notification.error(e.message);
    } finally {
      runInAction(() => (this.downloadInProgress = false));
    }
  }

  @computed get ticketsById() {
    return _.keyBy(this.tickets, '_id');
  }

  @computed get selectedTicketData() {
    return this.ticketsById[this.selectedTicket];
  }

  @computed get selectedIsClosed() {
    return this.selectedTicketData.isClosed || this.inUpdateTicket.get(this.selectedTicket);
  }

  @computed get ticketVersions() {
    return _.uniq(this.tickets.map(t => t.version));
  }
}

export default Support;
