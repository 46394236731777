import {action, observable, computed, runInAction} from 'mobx';
import notification from '@helpers/notification';

class Clients {
  @observable inProgress = false;
  @observable isGet = false;
  @observable list = [];

  constructor(rootStore) {
    this.api = rootStore.api.clients;
  }

  @action setInProgress(value) {
    this.inProgress = value;
  }

  @action setIsGet(value) {
    this.isGet = value;
  }

  @action setList(values) {
    this.list = values;
  }

  @action async getList() {
    try {
      this.setInProgress(true);
      const clients = await this.api.getClients();
      this.setList(clients);
      this.setIsGet(true);
    } catch (err) {
      notification.error(err.message);
    } finally {
      this.setInProgress(false);
    }
  }
}

export default Clients;
