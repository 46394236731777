import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@stores';
import ClientSelect from '@components/ClientSelect';
import {Box, HStack} from '@chakra-ui/react';
import DatesRange from '@components/DatesRange';
import Statistics from '../Statistics';

const Control = observer(() => {
  const {cardsLog} = useStore();

  return (
    <HStack spacing="24px">
      <Box maxW={240}>
        <ClientSelect value={cardsLog.clientId} handleSetValue={cardsLog.setClientId} disabled={cardsLog.inProgress} />
      </Box>
      {cardsLog.isGet && !cardsLog.isEmpty && (
        <>
          <DatesRange
            startDate={cardsLog.startDate}
            setStartDate={cardsLog.setStartDate}
            endDate={cardsLog.endDate}
            setEndDate={cardsLog.setEndDate}
            disabled={cardsLog.inProgress}
          />
          <Statistics />
        </>
      )}
    </HStack>
  );
});

export default Control;
