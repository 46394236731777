import numeral from 'numeral';

const supportStats = (_tickets, filters) => {
  let total = 0;
  let totalClosed = 0;

  const tickets = _tickets
    .filter(t => filters.priority === null || t.priority === filters.priority)
    .filter(t => filters.version === null || t.version === filters.version)
    .filter(t => filters.isClosed === null || t.isClosed === (filters.isClosed === 'closed'))
    .map(t => {
      const closingTimeInMinutes =
        t.isClosed && Math.round((new Date(t.updatedAt).getTime() - new Date(t.createdAt).getTime()) / 1000 / 60);
      return {
        title: t.title,
        version: t.version,
        priority: t.priority,
        status: t.isClosed ? 'Закрыт' : 'Открыт',
        createdAt: new Date(t.createdAt).toLocaleString('ru'),
        updatedAt: new Date(t.updatedAt).toLocaleString('ru'),
        reactedAt: t.reactedAt ? new Date(t.reactedAt).toLocaleString('ru') : '',
        closingTime: t.isClosed ? `${closingTimeInMinutes} мин.` : '',
        closingReason: t.closingReason || '',
        user: `${t.user} [${t.version}]`,
        _id: t._id,
      };
    });

  tickets.forEach(t => {
    total++;
    if (filters.isClosed === null && t.status === 'Закрыт') totalClosed++;
  });

  const head = ['Статистика по тиккетам', `Всего: ${total}`];
  if (filters.isClosed === null) head.push(`Закрыто: ${totalClosed}`);

  console.log(tickets);
  const columns = [
    {header: 'Номер запроса', key: '_id', width: 150},
    {header: 'Дата поступления запроса', key: 'createdAt', width: 150},
    {header: 'Тип обращения', key: 'type', width: 150},
    {header: 'Инициатор запроса', key: 'user', width: 50},
    {header: 'Приоритет', key: 'priority', width: 150},
    //{header: 'Статус', key: 'status', width: 150},
    {header: 'Дата решения', key: 'updatedAt', width: 150},
    {header: 'Дата реакции', key: 'reactedAt', width: 150},
    {header: 'Описание', key: 'title', width: 150},
    {header: 'Количество минут просрочки', key: 'closingTime', width: 150}, // хз что это, пока что updatedAt - createdAt
  ];

  return {columns, array: tickets, filename: `Саппорт стат. ${new Date().toLocaleDateString('ru')}`, head};
};

export default supportStats;
