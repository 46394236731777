import React from 'react';
import DashboardRoutes from '@routes/DashboardRoutes';
import DashboardLayout from '@layouts/DashboardLayout';

const Dashboard = () => (
  <DashboardLayout>
    <DashboardRoutes />
  </DashboardLayout>
);

export default Dashboard;
