import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ROUTES} from '@config/constants';
import Support from '@pages/Support';
import Logs from '@pages/Dashboard/Logs';
import SupportStats from '@pages/Dashboard/SupportStats';
import Notifications from '@pages/Dashboard/Notifications';

const DashboardRoutes = () => (
  <Switch>
    <Redirect exact from={ROUTES.DASHBOARD.value} to={ROUTES.DASHBOARD.CHATS.value} />
    <Route path={ROUTES.DASHBOARD.CHATS.value} component={Support} />
    <Route path={ROUTES.DASHBOARD.LOGS.value} component={Logs} />
    <Route path={ROUTES.DASHBOARD.SUPPORT_STATS.value} component={SupportStats} />
    <Route path={ROUTES.DASHBOARD.NOTIFICATIONS.value} component={Notifications} />
  </Switch>
);

export default DashboardRoutes;
